import React from 'react';
import Player from '@/components/player';

const AboutSection = () => {
  return (
    <>
    <div className="page-section about-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p>
            <b>Християнська Церква «Благодать»</b> - належить до об’єднання Української Церкви Християн Віри
            Євангельської (УЦХВЄ).
            </p>

            <p>
            Церква була заснована в 2011 році невеликою групою людей, які
            бажали виконувати Божу волю, у місті Білгород-Дністровський,
            Одеська область, Україна.
          </p>

          <p>
          Церква «Благодать» сповідує Євангельське вчення Ісуса Христа,
          вважає своїм обов’язком поширювати Боже Слово — Євангеліє для
          спасіння людей.
        </p>

        <p>Найвищим авторитетом для Церкви є Боже Слово — Біблія.</p>

        <p>
          Церква займає активну духовну та громадську позицію у вирішенні
          соціальних потреб і духовної підтримки всіх людей.
        </p>

        <p>
          Невід’ємною частиною життя кожної людини в церкві є постійне
          практичне життя з молитвою та глибоке вивчення Біблії як єдиного
          джерела Божої істини і волі про кожну людину та світ загалом.
        </p>
          </div>
        </div>
      
        </div>
    </div>
    <div className="page-section video-section">
    <div className="container">
      <div className="row">
        <div className="video_wrapper">
          <div className="col-md-10 col-xs-12">
            <Player url="https://youtu.be/C40VKOYAUeg" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="page-section video-section">
  <div className="container">
    <div className="row">
      <div className="video_wrapper">
        <div className="col-md-10 col-xs-12">
          <Player url="https://youtu.be/qH-cJWt3_iI" />
        </div>
      </div>
    </div>
  </div>
</div>
  </>
  );
};

export default AboutSection;
