import React from 'react';
import { useImage } from 'react-image';
import ContentLoader from 'react-content-loader';

const Image = ({ src, className, alt, viewBox, circle }) => {
  const { src: imageSrc, isLoading, error } = useImage({
    srcList: src,
    useSuspense: false,
  });

  if (isLoading || error) {
    return (
      <ContentLoader viewBox={viewBox} width="100%" height="100%">
        {circle ? (
          <circle cx="75" cy="75" r="250" />
        ) : (
          <rect x="16" y="16" rx="0" ry="0" width="100%" height="100%" />
        )}
      </ContentLoader>
    );
  }

  return <img src={imageSrc} className={className} alt={alt} />;
};

export default Image;
