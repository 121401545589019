import React from 'react';
import dynamic from 'next/dynamic';

const Pdf = dynamic(() => import('@/components/pdf'), { ssr: false });

const FundamentalsSection = () => {
  return (
    <div className="page-section">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Pdf />
        </div>
      </div>
    </div>
  </div>
  );
};

export default FundamentalsSection;

