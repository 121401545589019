import React, { Fragment } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import SectionTitle from './sectionTitle';
import Image from '@/components/image';

const Ministers = ({ page, other = false }) => {
  return (
    <Fragment>
      <div className="page-section ministers-section">
        <div className="container">
          <div className="row">
            {!page && <SectionTitle title="Служителі" />}
          </div>

          {!page && (
            <div className="row">
              <div className="members-container">
                <div className="member col-md-4 col-sm-4 wow fadeIn">
                  <div className="member-image-wrapper">
                    <div className="member-image">
                      <Image
                        src="/images/ministers/polinkevich.jpg"
                        alt="img"
                        viewBox="0 0 250 250"
                        circle
                      />
                    </div>
                  </div>
                  <h3>АНДРІЙ ПОЛІНКЕВИЧ</h3>
                  <span>Пастор</span>
                </div>
                <div
                  className="member col-md-4 col-sm-4 wow fadeIn"
                  data-wow-delay="400ms"
                >
                  <div className="member-image-wrapper">
                    <div className="member-image">
                      <Image
                        src="/images/ministers/slavik.jpg"
                        alt="img"
                        viewBox="0 0 250 250"
                        circle
                      />
                    </div>
                  </div>
                  <h3>ВʼЯЧЕСЛАВ НІКОЛАЄНКО</h3>
                  <span>Адміністратор церкви</span>
                </div>
                {/* <div
                  className="member col-md-4 col-sm-4 wow fadeIn"
                  data-wow-delay="200ms"
                >
                  <div className="member-image-wrapper">
                    <div className="member-image">
                      <Image
                        src="/images/ministers/stanevich.jpg"
                        alt="img"
                        viewBox="0 0 250 250"
                        circle
                      />
                    </div>
                  </div>
                  <h3>ВЛАДИМИР СТАНЕВИЧ</h3>
                  <span>
                    Дьякон, ответственный за материально-технический комплекс
                    церкви
                  </span>
                </div>
                */}

              </div>
            </div>
          )}
          {other && (
            <>
              <div className="row">
                <div
                  className={clsx('members-container-other', {
                    'members-container-other__page': page,
                  })}
                >
                  <div className="member col-lg-3 col-md-6 col-sm-6 col-xs-12 wow fadeIn">
                    <div className="member-image-wrapper">
                      <div className="member-image">
                        <Image
                          src="/images/ministers/polinkevich.jpg"
                          alt="img"
                          viewBox="0 0 250 250"
                          circle
                        />
                      </div>
                    </div>
                    <h3>АНДРЕЙ ПОЛИНКЕВИЧ</h3>
                    <span>Пастор</span>
                  </div>
                  <div
                    className="member col-lg-3 col-md-6 col-sm-6 col-xs-12 wow fadeIn"
                    data-wow-delay="200ms"
                  >
                    <div className="member-image-wrapper">
                      <div className="member-image">
                        <Image
                          src="/images/ministers/stanevich.jpg"
                          alt="img"
                          viewBox="0 0 250 250"
                          circle
                        />
                      </div>
                    </div>
                    <h3>ВЛАДИМИР СТАНЕВИЧ</h3>
                    <span>
                      Дьякон, ответственный за материально-технический комплекс
                      церкви
                    </span>
                  </div>
                  <div className="member col-lg-3 col-md-6 col-sm-6 col-xs-12 wow fadeIn">
                    <div className="member-image-wrapper">
                      <div className="member-image">
                        <Image
                          src="/images/ministers/slavik.jpg"
                          alt="img"
                          viewBox="0 0 250 250"
                          circle
                        />
                      </div>
                    </div>
                    <h3>ВЯЧЕСЛАВ НИКОЛАЕНКО</h3>
                    <span>Дьякон</span>
                  </div>
                  <div
                    className="member col-lg-3 col-md-6 col-sm-6 col-xs-12 wow fadeIn"
                    data-wow-delay="400ms"
                  >
                    <div className="member-image-wrapper">
                      <div className="member-image">
                        <Image
                          src="/images/ministers/sergey_d.jpg"
                          alt="img"
                          viewBox="0 0 250 250"
                          circle
                        />
                      </div>
                    </div>
                    <h3>СЕРГЕЙ ШИЛИН</h3>
                    <span>Дьякон</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="members-container-other">
                  <div className="member col-lg-3 col-md-6 col-sm-6 col-xs-12 wow fadeIn">
                    <div className="member-image-wrapper">
                      <div className="member-image">
                        <Image
                          src="/images/ministers/iliya.jpg"
                          alt="img"
                          viewBox="0 0 250 250"
                          circle
                        />
                      </div>
                    </div>
                    <div className="member-name-wrapper">
                      <h3>ИЛЬЯ ФЛИСЮК</h3>
                      <a
                        className="member-link"
                        href="https://www.instagram.com/el.flis"
                        title="Instagram"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i className="fab fa-instagram member-icon" />
                      </a>
                    </div>
                    <span>Администратор церкви</span>
                  </div>
                  <div
                    className="member col-lg-3 col-md-6 col-sm-6 col-xs-12 wow fadeIn"
                    data-wow-delay="400ms"
                  >
                    <div className="member-image-wrapper">
                      <div className="member-image">
                        <Image
                          src="/images/ministers/roman_p.jpg"
                          alt="img"
                          viewBox="0 0 250 250"
                          circle
                        />
                      </div>
                    </div>
                    <div className="member-name-wrapper">
                      <h3>РОМАН ПОЛИНКЕВИЧ</h3>
                      <a
                        className="member-link"
                        href="https://www.instagram.com/polinkevych"
                        title="Instagram"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i className="fab fa-instagram member-icon" />
                      </a>
                    </div>
                    <span>
                      Дьякон, лидер группы прославления, ответственный за
                      молодежное служение
                    </span>
                  </div>
                  <div
                    className="member col-lg-3 col-md-6 col-sm-6 col-xs-12 wow fadeIn"
                    data-wow-delay="400ms"
                  >
                    <div className="member-image-wrapper">
                      <div className="member-image">
                        <Image
                          src="/images/ministers/pavel.jpg"
                          alt="img"
                          viewBox="0 0 250 250"
                          circle
                        />
                      </div>
                    </div>
                    <h3>ПАВЕЛ ВАЩУК</h3>
                    <span>
                      Дьякон, ответственный за подростковое и евангелизационное
                      служения
                    </span>
                  </div>
                  <div
                    className="member col-lg-3 col-md-6 col-sm-6 col-xs-12 wow fadeIn"
                    data-wow-delay="200ms"
                  >
                    <div className="member-image-wrapper">
                      <div className="member-image">
                        <Image
                          src="/images/ministers/oleg.jpg"
                          alt="img"
                          viewBox="0 0 250 250"
                          circle
                        />
                      </div>
                    </div>
                    <h3>ОЛЕГ ДЕРЕВЕНЧА</h3>
                    <span>Директор воскресной школы</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="members-container-other">
                  <div
                    className="member col-lg-3 col-md-6 col-sm-6 col-xs-12 wow fadeIn"
                    data-wow-delay="400ms"
                  >
                    <div className="member-image-wrapper">
                      <div className="member-image">
                        <Image
                          src="/images/ministers/andrey_g.jpg"
                          alt="img"
                          viewBox="0 0 250 250"
                          circle
                        />
                      </div>
                    </div>
                    <h3>АНДРЕЙ ГУЦУЛ</h3>
                    <span>Ответственный за группу порядка</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Ministers;


{/*!page && (
  <div className="container mb-110">
    <div className="container-p-75-2">
      <div className="port-view-more-cont">
        <Link href="/about/ministers">
          <a className="port-view-more">ВСЕ СЛУЖИТЕЛИ</a>
        </Link>
      </div>
    </div>
  </div>
)*/}
