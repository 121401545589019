import React from 'react';
import ReactPlayer from 'react-player/lazy';

const Player = ({ url, playing= false }) => (
  <ReactPlayer
    playing={playing}
    controls
    className="react-player"
    width="100%"
    height="100%"
    url={url}
  />
);

export default Player;
