import React from 'react';

const ScheduleSection = () => {
  return (
    <div style={{ paddingTop: 0 }} className="page-section about-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 ministry-schedule">
            <h3>Розклад Богослужінь церкви «Благодать»:</h3>
            <p>
              <b>Вівторок</b>: Служіння - 18:00
            </p>
            <p>
              <b>Четвер</b>: Малі групи - 15:00, 18:00
            </p>
            <p>
              <b>Субота</b>: Дитячий клуб "Пілігрім" - 11:00
            </p>
            <p>
              <b>Неділя</b>: Служіння - 14:00
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleSection;
// <p>
// <b>Суббота</b> - Клуб "Пилигрим" - 11:00 - 14:00
// </p>
// <div className="col-md-12">
// <div className="pdf-wrapper">
//   <h3>Основы вероучения нашего братства:</h3>
//   <button
//     onClick={() => {
//       Router.push('/doctrine');
//     }}
//     className="button small gray"
//   >
//     ПРОСМОТР
//   </button>
// </div>
// </div>
