import React from 'react';
import Link from 'next/link';

const SectionTitle = ({ title, link }) => {
  return (
    <div className="col-md-12">
      <div className="mb-50">
        <h2 className="section-title pr-0">
          {title}
          {link && (
            <Link href="/news">
              <a className="section-more right">ВСЕ НОВОСТИ</a>
            </Link>
          )}
        </h2>
      </div>
    </div>
  );
};

export default SectionTitle;
